import IconClose from '@/shared/ui-kit/icons/icon-close';
import IconDw from '@/shared/ui-kit/icons/icon-dw';
import { Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

type TProps = {
  onClose: () => void;
  onControl: () => void;
};

export const InfoBanner = ({ onClose, onControl }: TProps) => {
  const t = useTranslations();
  return (
    <Flex
      padding={{ base: '10px', md: '12px 20px' }}
      marginX={{ base: 0, lg: '40px' }}
      direction='row'
      width={{ base: '100%', lg: 'calc(100% - 80px)' }}
      bgColor='yellow-brand.400'
      borderRadius='20px'
      alignItems={{ md: 'center' }}
      justifyContent='space-between'
    >
      <Flex
        alignItems={{ md: 'center' }}
        gap='15px'
      >
        <IconDw
          color='text&icon.tx-primary'
          boxSize='32px'
        />
        <Flex
          flexWrap='wrap'
          alignItems='center'
          gap='20px'
        >
          <Text
            textStyle='heading-large-md'
            color='text&icon.tx-primary'
          >
            {t('HomePage.infoBannerDescription')}
          </Text>
          <Button
            variant='solid'
            colorScheme='black'
            onClick={onControl}
            padding='15px !important'
            size='sm'
          >
            <Text textStyle='heading-large-sm'>
              {t('HomePage.infoBannerButton')}
            </Text>
          </Button>
        </Flex>
      </Flex>
      <IconButton
        aria-label='close'
        colorScheme='transparent'
        variant='solid'
        size='sm'
        onClick={onClose}
      >
        <IconClose color='text&icon.tx-primary' />
      </IconButton>
    </Flex>
  );
};
