import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { Link } from '@chakra-ui/next-js';
import Avatar from '@ui-kit/atoms/avatar/avatar';
import Button from '@ui-kit/atoms/button/button';
import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import IconClose from '@ui-kit/icons/icon-close';
import IconMenu from '@ui-kit/icons/icon-menu';
import MenuBurger from '@ui-kit/molecules/menu-burger/menu-burger';
import { MenuHeaderDesktop } from '../../molecules/menu-header-desktop';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import { useTranslations } from 'next-intl';

type Props = {
  onSignIn: () => void;
  isAuthenticated: boolean;
  logo: ReactNode;
  children: ReactNode;
  avatarUrl: string | null;
  profile: {
    fullName: string | null;
    nickName: string | null;
  } | null;
  isMenuOpen: boolean;
  onSetMenuOpen: (val: boolean) => void;
  centerNavigateLinks: ReactNode;
  onSignOut: () => void;
};

export const Header: FC<Props> = ({
  onSignIn,
  logo,
  children,
  isAuthenticated,
  profile,
  avatarUrl,
  isMenuOpen,
  onSetMenuOpen,
  centerNavigateLinks,
  onSignOut,
}) => {
  const isMobile = useIsMobile();
  const t = useTranslations();
  return (
    <>
      <MenuBurger
        onCloseMenu={() => onSetMenuOpen(false)}
        isMenuOpen={isMenuOpen}
        header={
          isAuthenticated ? (
            <>
              {profile?.fullName && (
                <Text
                  as='h2'
                  mt='20px'
                  textStyle='heading-small-2xl'
                >
                  {profile.fullName}
                </Text>
              )}
              {profile?.nickName && (
                <Text
                  mt='20px'
                  textStyle='text-medium-sm'
                  color='text&icon.tx-secondary'
                >
                  @{profile.nickName}
                </Text>
              )}
            </>
          ) : (
            <>
              <Text
                as='h2'
                textStyle='heading-small-2xl'
              >
                {t('Header.signIn')}
              </Text>
              <Text
                mt='20px'
                textStyle='text-regular-lg'
              >
                {t('Header.toJoinTheCampaignFollowYourFavoritesAndEarnMoney')}
              </Text>

              <Button
                mt='20px'
                size='md'
                colorScheme='white'
                onClick={onSignIn}
                w='auto'
              >
                <Text textStyle='text-medium-lg'>{t('Header.signIn')}</Text>
              </Button>
            </>
          )
        }
      >
        {children}
      </MenuBurger>

      <Flex
        w='full'
        pr={{ base: '10px', lg: '40px' }}
        pl={{ base: '20px', lg: '40px' }}
        minH={{ base: '65px', lg: '100px' }}
        justify='space-between'
        align='center'
        layerStyle='header-gradient'
        position='relative'
      >
        <Box zIndex={1}>{logo}</Box>

        <Flex
          display={{ base: 'none', md: 'flex' }}
          position='absolute'
          zIndex={0}
          w='full'
          left={0}
          top={0}
          h='100%'
          justifyContent='center'
          alignItems='center'
        >
          {centerNavigateLinks}
        </Flex>

        <Flex
          as={'nav'}
          gap='20px'
          align='center'
          zIndex={1}
        >
          {isMobile ? (
            <>
              {isAuthenticated ? (
                <Link
                  prefetch={false}
                  href={'/profile'}
                >
                  <Avatar
                    size='xs'
                    src={avatarUrl ?? undefined}
                  />
                </Link>
              ) : (
                <Button
                  color='text&icon.tx-contrast'
                  onClick={onSignIn}
                  rounded={0}
                  bg='transparent'
                  _active={{ bg: 'transparent' }}
                  _focus={{ bg: 'transparent' }}
                  _hover={{ bg: 'transparent' }}
                >
                  <Text textStyle='text-medium-lg'>{t('Header.signIn')}</Text>
                </Button>
              )}

              <IconButton
                onClick={
                  isMenuOpen
                    ? () => onSetMenuOpen(false)
                    : () => onSetMenuOpen(true)
                }
                size='sm'
                aria-label='menu'
                icon={isMenuOpen ? <IconClose /> : <IconMenu />}
                rounded={0}
                bg='transparent'
                color='black&white.white'
                _active={{ bg: 'transparent' }}
                _focus={{ bg: 'transparent' }}
                _hover={{ bg: 'transparent' }}
              />
            </>
          ) : (
            <>
              {isAuthenticated ? (
                <>
                  <Link
                    prefetch={false}
                    href={'/profile'}
                  >
                    <Flex
                      direction='column'
                      maxW='230px'
                    >
                      <Text
                        textStyle='one-line-text-medium-lg'
                        noOfLines={1}
                      >
                        {profile?.fullName}
                      </Text>

                      <Text
                        textStyle='one-line-text-medium-sm'
                        color='text&icon.tx-secondary'
                        noOfLines={1}
                      >
                        {profile?.nickName}
                      </Text>
                    </Flex>
                  </Link>

                  <MenuHeaderDesktop
                    avatarUrl={avatarUrl}
                    profile={profile}
                    onSignOut={onSignOut}
                  />
                </>
              ) : (
                <Button
                  color='text&icon.tx-contrast'
                  onClick={onSignIn}
                  rounded={0}
                  bg='transparent'
                  _active={{ bg: 'transparent' }}
                  _focus={{ bg: 'transparent' }}
                  _hover={{ bg: 'transparent' }}
                >
                  <Text textStyle='text-medium-lg'>{t('Header.signIn')}</Text>
                </Button>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
