import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M14.6637 4.42486L12.5 3L10.3363 4.42486L7.75 4.27276L6.58859 6.58859L4.27276 7.75L4.42486 10.3363L3 12.5L4.42486 14.6637L4.27276 17.25L6.58859 18.4114L7.75 20.7272L10.3363 20.5751L12.5 22L14.6637 20.5751L17.25 20.7272L18.4114 18.4114L20.7272 17.25L20.5751 14.6637L22 12.5L20.5751 10.3363L20.7272 7.75L18.4114 6.58859L17.25 4.27276L14.6637 4.42486ZM13.2961 6.7711L13.2936 14.4017L11.4936 14.4011L11.4961 6.77051L13.2961 6.7711ZM13.2961 16.0017L13.2912 18.002L11.4912 17.9976L11.4961 15.9973L13.2961 16.0017Z'
      fill='currentColor'
    />
  ),
});

const IconDw: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconDw);
