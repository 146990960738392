'use client';

import { signOut } from 'next-auth/react';
import { ReactNode, useEffect } from 'react';
import type { Session } from 'next-auth';

export default function SessionGuard({
  children,
  session,
}: {
  children: ReactNode;
  session: Session | null;
}) {
  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      signOut(); //// https://jira.kode.ru/browse/DMZ-1004
    }
  }, [session]);

  return <>{children}</>;
}
