import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: [
    <path
      key='path-1'
      d='M21 8.2998H3V9.6998H21V8.2998Z'
      fill='currentColor'
    />,
    <path
      key='path-2'
      d='M21 14.2998H3V15.6998H21V14.2998Z'
      fill='currentColor'
    />,
  ],
});

const IconMenu: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconMenu);
