'use client';

import { useUnit } from 'effector-react';
import {
  $globalErrors,
  resolveErrorById,
  resetErrors,
} from '@/entities/global-error/model';

export const useGlobalErrors = () =>
  useUnit([$globalErrors, resolveErrorById, resetErrors]);
