'use client';

import { useGetProfile } from '@/entities/profile';
import Header from '@/shared/ui-kit/organisms/header/header';
import { Image, Link } from '@chakra-ui/next-js';
import { Flex, Text } from '@chakra-ui/react';
import { signIn, signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
type Props = {
  isAuthenticated: boolean;
};

export const DemuzoHeader = ({ isAuthenticated }: Props) => {
  const t = useTranslations();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data: profile } = useGetProfile(isAuthenticated);
  const [isSignInProgress, setIsSignInProgress] = useState(false);
  const handleSignIn = async () => {
    if (!isSignInProgress) {
      await signIn('keycloak', { callbackUrl: '/campaigns' }).finally(() =>
        setIsSignInProgress(false),
      );
    }
  };

  const handleSignOut = async () => {
    await signOut({ callbackUrl: '/' });
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Header
      onSignOut={handleSignOut}
      centerNavigateLinks={
        <Flex
          as={'nav'}
          gap='40px'
        >
          <Link
            prefetch={false}
            href='/news'
          >
            {t('Header.navigation.news')}
          </Link>
          <Link
            prefetch={false}
            href='/campaigns'
          >
            {t('Header.navigation.allCampaigns')}
          </Link>
          <Link
            prefetch={false}
            href='/faq'
          >
            {t('Header.navigation.faq')}
          </Link>
        </Flex>
      }
      logo={
        <Link
          prefetch={false}
          onClick={handleCloseMenu}
          href='/'
        >
          <Image
            src='/images/logo-white.svg'
            alt='logo'
            width={104}
            height={25}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </Link>
      }
      isMenuOpen={isMenuOpen}
      onSetMenuOpen={setIsMenuOpen}
      avatarUrl={profile ? profile.avatarUrl : null}
      profile={
        profile
          ? {
              nickName: profile.nickname || '',
              fullName: `${profile.firstName || ''} ${profile.surname || ''}`,
            }
          : null
      }
      isAuthenticated={isAuthenticated}
      onSignIn={handleSignIn}
    >
      {isAuthenticated ? (
        <>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/profile'
          >
            <Text textStyle='heading-small-2xl'>
              {t('Header.menu.myProfile')}
            </Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/profile/subscriptions'
          >
            <Text textStyle='heading-small-2xl'>
              {t('Header.menu.subscriptions')}
            </Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/news'
          >
            <Text textStyle='heading-small-2xl'>{t('Header.menu.news')}</Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/campaigns'
          >
            <Text textStyle='heading-small-2xl'>
              {t('Header.menu.allCampaigns')}
            </Text>
          </Link>

          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/profile/settings'
            mt='20px'
          >
            <Text textStyle='text-medium-lg'>{t('Header.menu.settings')}</Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='mailto:support@demuzo.com'
          >
            <Text textStyle='text-medium-lg'>{t('Header.menu.support')}</Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/faq'
          >
            <Text textStyle='text-medium-lg'>{t('Header.menu.faq')}</Text>
          </Link>

          <Link
            prefetch={false}
            onClick={() => {
              handleSignOut();
              handleCloseMenu();
            }}
            href='#'
            mt='20px'
          >
            <Text textStyle='text-medium-lg'>{t('Header.menu.logOut')}</Text>
          </Link>
        </>
      ) : (
        <>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/'
          >
            <Text textStyle='heading-small-2xl'>
              {t('Header.menu.mainPage')}
            </Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/news'
          >
            <Text textStyle='heading-small-2xl'>{t('Header.menu.news')}</Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/campaigns'
          >
            <Text textStyle='heading-small-2xl'>
              {t('Header.menu.allCampaigns')}
            </Text>
          </Link>

          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='mailto:support@demuzo.com'
            mt='20px'
          >
            <Text textStyle='text-medium-lg'>{t('Header.menu.support')}</Text>
          </Link>
          <Link
            prefetch={false}
            onClick={handleCloseMenu}
            href='/faq'
          >
            <Text textStyle='text-medium-lg'>{t('Header.menu.faq')}</Text>
          </Link>
        </>
      )}
    </Header>
  );
};

export default DemuzoHeader;
